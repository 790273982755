.content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.questions {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: var(--g-spacing-xl);
}

.separator {
    border-bottom: var(--g-border-width-xs) solid
        var(--s-color-separator-standard);
    margin: var(--g-spacing-xl) 0;
}

.error {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: var(--g-spacing-md);
}

.footer {
    display: flex;
    flex-direction: column;
    gap: var(--g-spacing-xl);
    align-items: center;
}
