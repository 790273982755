@use "@wojo/ui/mixins" as *;

$day-marker-width: 7px;
$day-wrapper-spacing: var(--g-spacing-md);
$day-by-day-border-width: 1px;

.section {
    margin-top: var(--g-spacing-lg);
    margin-bottom: var(--g-spacing-xl);

    @include breakpoint-md {
        margin-bottom: var(--g-spacing-xxl);
    }
}

.day-by-day {
    border-left: $day-by-day-border-width solid var(--s-color-text-primary);
    border-image: linear-gradient(
            to top,
            transparent,
            currentColor var(--g-spacing-md)
        )
        1;
    padding-left: $day-wrapper-spacing;
    padding-bottom: var(--g-spacing-md);
    margin-bottom: var(--g-spacing-md);
    contain: layout;

    & > .day:first-child > .day-section-title,
    & > .day--overnight:first-child .day:first-child .day-section-title {
        // Hides the top of the border line that appears above the dot icon
        &::before {
            content: "";
            position: absolute;
            width: 1px;
            top: 0;
            height: 50%;
            border-left: $day-by-day-border-width solid
                var(--s-color-surface-secondary);
            left: calc(
                (#{$day-wrapper-spacing} * -1) - (#{$day-by-day-border-width})
            );

            // Hides it for high contrast/forced colors mode too
            @media (forced-colors: active) {
                border-color: Canvas;
            }
        }
    }

    &--expanded {
        border-image: none;
        padding-bottom: 0;
        margin-bottom: var(--g-spacing-xl);
    }
}

.day {
    & + & {
        margin-top: var(--g-spacing-xl);
    }
}

.day-section-title {
    position: relative;
}

.day-title {
    margin-bottom: var(--g-spacing-sm);
}

.day-overnight-title {
    margin-bottom: var(--g-spacing-md);
}

.day-marker {
    fill: currentColor;
    position: absolute;
    // Positions the dot icon in the middle of the left border line
    left: calc(
        (#{$day-wrapper-spacing} * -1) -
            (#{$day-marker-width} + #{$day-by-day-border-width}) / 2
    );
    top: 50%;
    transform: translateY(-50%);
    width: $day-marker-width;
    height: $day-marker-width;
}

.excursion {
    padding: 0;
    display: flex;
    width: 100%;
    align-items: stretch;
    margin-top: var(--g-spacing-lg);
}

.excursion-image-container {
    position: relative;
    width: 40%;
    overflow: hidden;
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;
    transition: width var(--g-duration-xshort) ease;
}

.excursion-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        object-position: center;
    }
}

.excursion-modal-image {
    display: flex;
    aspect-ratio: 16/9;
    margin-bottom: var(--g-spacing-lg);
    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        object-position: center;
    }
}

.excursion-modal-detail {
    margin-bottom: var(--g-spacing-sm);
    display: flex;
    align-items: center;
    gap: var(--g-spacing-sm);
}

.print-button {
    margin-bottom: var(--g-spacing-md);
    & > * {
        vertical-align: middle;
    }
}

.physical-activity-markdown {
    ul {
        padding-left: var(--g-spacing-md);
    }
}
