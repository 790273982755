@use "@wojo/ui/mixins" as *;

.container {
    margin-top: var(--g-spacing-lg);
    margin-bottom: var(--g-spacing-xl);

    @include breakpoint-md {
        margin-bottom: var(--g-spacing-xxl);
    }
}

.markdown {
    ul {
        padding-left: var(--g-spacing-md);
    }
}
