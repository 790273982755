.exit {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--g-spacing-lg);
    padding-bottom: var(--g-spacing-md);

    &-text-wrapper {
        display: flex;
        flex-direction: column;
        gap: var(--g-spacing-sm);
        > * {
            text-align: center;
        }
    }

    &-footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--g-spacing-md);
        margin-bottom: var(--g-spacing-md);
    }
}
